import { BsTwitter, BsTelegram, BsMedium } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col md:flex-row md:justify-between lg:mt-56 px-5 md:px-0 max-w-[23rem] sm:max-w-[33.75rem] md:max-w-[45rem] lg:max-w-[60rem] xl:max-w-[71.25rem] mx-auto">
        <div>
          <h1 className="text-[1.9rem] font-semibold text-main-green">{t('FOOTER_TITLE')}</h1>
          <div className="flex text-sm font-extralight text-main-grey">
            {/* <a
              href="https://docsend.com/view/wq7qxzjk7zsd3wph"
              target="blank"
              className="flex mr-4 hover:text-white duration-200"
            >
              <Image src="/assets/layout/paper.svg" width={9} height={12} alt="paper" />
              <p className="ml-[0.44rem]">{t('FOOTER_WHITEPAPER')}</p>
            </a> */}
            <a
              href="https://tally.so/r/3xN4Ro"
              target="blank"
              className="flex hover:text-white duration-200"
            >
              <Image src="/assets/layout/ambassador.svg" width={10} height={12} alt="ambassador" />
              <p className="ml-[0.44rem]">{t('FOOTER_AMBASSADOR')}</p>
            </a>
          </div>
        </div>
        <div className="flex text-main-green text-2xl space-x-5 mt-7 md:mt-auto child:p-3 child:rounded-full child:border child:border-main-green">
          <a
            className="hover:text-main-blue hover:bg-main-green duration-500"
            href="https://twitter.com/stackai_xyz"
            target="blank"
          >
            <span className="sr-only">Twitter link</span>
            <BsTwitter />
          </a>
          <a
            className="hover:text-main-blue hover:bg-main-green duration-500"
            href="https://t.me/StackAI_xyz"
            target="blank"
          >
            <span className="sr-only">Telegram link</span>
            <BsTelegram />
          </a>
          {/* <a
            className="hover:text-main-blue hover:bg-main-green duration-500"
            href="https://discord.gg/w6KREwnpBj"
            target="blank"
          >
            <span className="sr-only">Discord link</span>
            <FaDiscord />
          </a> */}
          <a
            className="hover:text-main-blue hover:bg-main-green duration-500"
            href="https://medium.com/stackos"
            target="blank"
          >
            <span className="sr-only">Medium link</span>
            <BsMedium />
          </a>
        </div>
      </div>
      <div className="py-6 border-t border-main-green text-center mt-6">
        <a
          href="https://www.stackai.xyz"
          target="blank"
          className="text-white text-sm font-normal hover:underline"
        >
          {t('FOOTER_COPYRIGHT')}
        </a>
      </div>
    </div>
  );
};

export default Footer;
